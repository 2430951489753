import React from 'react'
import { graphql } from 'gatsby'
import Vita from '../components/Vita'

class VitaTemplate extends React.Component {
  render() {
    return (
      <Vita mdx={ this.props.data.mdx } />
    )
  }
}

export default VitaTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        cvid
        name
        position
        category
        date(formatString: "DD. MMMM YYYY")
      }
      body
    }
  }
`
