import React from 'react'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from './seo'

import styles from "./Vita.module.styl"
import printer from "./printer.svg"

export default class Vita extends React.Component {
  render() {
    const cv = this.props.mdx
    const title = cv.frontmatter.name + ' CV'
    return (
      <div className={ styles.vita }>
        <SEO title={ title } description={ title } />
        <div className={ styles.container }>
          <div className={ styles.icons } onClick={ function() { window.print() } }>
            <img src={ printer } alt="print cv"/>
            <span>print</span>
          </div>
          <MDXRenderer>{ cv.body }</MDXRenderer>
        </div>
      </div>
    )
  }
}
